/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-05 09:50:19 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2022-04-Tu 03:07:44
 */
const hostUrl = "https://puliedu.1plus.store/";
// const hostUrl = "https://api.pulijiaoyu.org.cn/";

export default {
  hostUrl
};