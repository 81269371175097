import Vue from 'vue'
import Vuex from 'vuex'
import tagsView from './modules/tagsView'
// import permission from './modules/permission'
import theme from './modules/theme'
// import user from './modules/user'
import diypage from './modules/diypage'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    tagsView,
    // permission,
    theme,
    diypage,
    // user
  }

})