const theme = {
  state: {
    haveSidebar: false, // 是否拥有二级菜单
    selectnav: '', // 选中的菜单
    helpHide: true, // 帮助栏是否隐藏
    defaultColor: '#54ceea'// 主题色
  },
  mutations: {

    // 是否拥有二级菜单
    SETHAVEESIDEBAR (state, msg) {
      state.haveSidebar = msg
    },
    // 选中的菜单
    SETSELECTNAV (state, msg) {
      state.selectnav = msg
    },
    // 设置主题色
    SETCOLOR (state, msg) {
      state.defaultColor = msg
    },
    // 帮助栏是否隐藏
    SETHELPHIDE (state, msg) {
      state.helpHide = msg
    }
  },
  actions: {

  }
}

export default theme
