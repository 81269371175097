const diypage = {
  state: {
    diyList: [], // 自定义页面配置
    diyItem: {}, // 选中的模块
    diyKey: '', // 选中的序号
    offsetTop: '', // 距离顶部的距离
    userSelect: '0' // 个人中心选择的模板
  },

  mutations: {
    // 个人中心选择的模板
    SETUSERSELECT: function (state, msg) {
      state.userSelect = msg
    },
    // 距离顶部的距离
    SETOFFSETTOP: function (state, msg) {
      state.offsetTop = msg
    },
    // 选中的模块
    SETDIYITEM: function (state, msg) {
      state.diyItem = msg
    },
    // 选中的序号
    SETDIYKEY: function (state, msg) {
      state.diyKey = msg
    },
    // 追加自定义页面配置
    SETDIYLIST(state, msg) {
      state.diyList.push(msg)
    },
    // 从开头追加自定义页面配置
    SETDIYLISTPOP(state, msg) {
      state.diyList.unshift(msg)
    },
    // 更新自定义页面配置
    SETUPDATADIYLIST(state, msg) {
      state.diyList = msg
    }

  },
  actions: {
    adddataDiyList({
      commit
    }, view) {
      commit('SETDIYLIST', view)
    },
    updataDiyList({
      commit
    }, view) {
      commit('SETUPDATADIYLIST', view)
    },
    updatadiykey({
      commit
    }, view) {
      commit('SETDIYKEY', view)
    }

  }
}

export default diypage