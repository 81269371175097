import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes: [{
    path: "/",
    name: "custom",
    component: () => import("@/views/custom.vue"),
    meta: {
      title: "首页",
    },
  }, {
    path: "/custom",
    name: "custom",
    component: () => import("@/views/custom.vue"),
    meta: {
      title: "首页",
    },
  }],
})
export default router