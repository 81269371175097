import Vue from 'vue'
import App from '@/App.vue'
import store from "@/store/index";
import router from "@/router/index";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/style/index.scss'
Vue.use(ElementUI);
Vue.use(Vant);
import utils from "@/utils/index";
Vue.prototype.GLOBAL = utils;

import common from "@/utils/common.js";
Vue.prototype.common = common;
Object.keys(common).forEach((key) => {
  Vue.filter(key, common[key]);
});
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')